import CwebIcon from "../assets/Icons/Networks/cweb.png";
import BtcIcon from "../assets/Icons/Networks/bitcoin.svg";
import BtcCashIcon from "../assets/Icons/Networks/bitcoin-cash.png";
import LiteIcon from "../assets/Icons/Networks/lite.svg";
import EthIcon from "../assets/Icons/Networks/ethereum.svg";
import EgldIcon from "../assets/Icons/Networks/elrond.svg";
import PolyIcon from "../assets/Icons/Networks/polygon.svg";
import Doge from "../assets/Icons/Networks/doge.svg";
import BNBIcon from "../assets/Icons/Networks/bnb.png";
import KujiIcon from "../assets/Icons/Networks/kuji.png";
import ArbIcon from "../assets/Icons/Networks/arb.png";
import TronIcon from "../assets/Icons/Networks/tron.svg";
import { NetworkName } from "generated/graphql";
import { NetworkName as devNetworkName } from "generated/graphql-devnet";
import { BlockChain } from "components/TableContainer/CustomizedMui";
import { NetworkName as FetchNetwork } from "@coinweb/claims-client";
export const BlockChains: {
  [key in devNetworkName | NetworkName]: BlockChain;
} = {
  BITCOIN: {
    networkName: NetworkName.Bitcoin,
    label: "BTC",
    hover: "Bitcoin",
    currency: "BTC",
    icon: BtcIcon,
    fetchNetwork: FetchNetwork.Bitcoin,
    searchQueries: ["btc", "bitcoin", "l2v2btc"],
  },
  BITCOIN_CASH: {
    networkName: NetworkName.BitcoinCash,
    label: "BCH",
    hover: "Bitcoin Cash",
    currency: "BCH",
    icon: BtcCashIcon,
    fetchNetwork: FetchNetwork.BitcoinCash,
    searchQueries: ["btc_cash", "bch", "bitcoincash", "bitcoin_cash", "l2v2bch"],
  },
  BITCOIN_CASH_TESTNET: {
    networkName: NetworkName.BitcoinCashTestnet,
    label: "tBCH",
    hover: "Bitcoin Cash Testnet",
    currency: "BCH",
    icon: BtcCashIcon,
    fetchNetwork: FetchNetwork.BitcoinCashTestnet,
    searchQueries: ["btc_cash_testnet", "tbch", "bitcoincashtestnet", "btc_cash_t", "bitcoin_cash_testnet"],
  },
  BITCOIN_TESTNET: {
    networkName: NetworkName.BitcoinTestnet,
    label: "tBTC",
    hover: "Bitcoin Testnet",
    currency: "BTC",
    icon: BtcIcon,
    fetchNetwork: FetchNetwork.BitcoinTestnet,
    searchQueries: ["btctestnet", "bitcoin", "btc_testnet", "tbtc", "bitcointestnet"],
  },
  LITECOIN: {
    networkName: NetworkName.Litecoin,
    label: "LTC",
    hover: "Litecoin",
    currency: "LTC",
    icon: LiteIcon,
    fetchNetwork: FetchNetwork.Litecoin,
    searchQueries: ["ltc", "litecoin", "l2v2ltc"],
  },
  LITECOIN_TESTNET: {
    networkName: NetworkName.LitecoinTestnet,
    label: "tLTC",
    hover: "Litecoin Testnet",
    currency: "LTC",
    icon: LiteIcon,
    fetchNetwork: FetchNetwork.LitecoinTestnet,
    searchQueries: ["tltc", "litecoin_testnet", "litecointestnet"],
  },
  DOGECOIN: {
    networkName: NetworkName.Dogecoin,
    label: "DOGE",
    hover: "Dogecoin",
    currency: "DOGE",
    icon: Doge,
    fetchNetwork: FetchNetwork.Dogecoin,
    searchQueries: ["doge", "dogecoin"],
  },
  DOGECOIN_TESTNET: {
    networkName: NetworkName.DogecoinTestnet,
    label: "tDOGE",
    hover: "Dogecoin Testnet",
    currency: "DOGE",
    icon: Doge,
    fetchNetwork: FetchNetwork.DogecoinTestnet,
    searchQueries: ["tdoge", "dogecoin_testnet", "dogecointestnet"],
  },
  ETHEREUM: {
    networkName: NetworkName.Ethereum,
    label: "ETH",
    hover: "Ethereum",
    currency: "ETH",
    icon: EthIcon,
    fetchNetwork: FetchNetwork.Ethereum,
    searchQueries: ["eth", "ethereum", "ether", "l2v2eth"],
  },
  ETHEREUM_TESTNET: {
    networkName: NetworkName.EthereumTestnet,
    label: "tETH",
    hover: "Ethereum Testnet",
    currency: "ETH",
    icon: EthIcon,
    fetchNetwork: FetchNetwork.EthereumTestnet,
    searchQueries: ["teth", "ethereum_testnet", "ethereumtestnet", "ether_testnet", "ethertestnet"],
  },

  ELROND: {
    networkName: NetworkName.Elrond,
    label: "EGLD",
    hover: "MultiversX",
    currency: "EGLD",
    icon: EgldIcon,
    fetchNetwork: FetchNetwork.Elrond,
    searchQueries: ["egld", "multiversx", "l2v2egld", "elrond"],
  },
  ELROND_TESTNET: {
    networkName: NetworkName.ElrondTestnet,
    label: "tEGLD",
    hover: "MultiversX Testnet",
    currency: "BCH",
    icon: EgldIcon,
    fetchNetwork: FetchNetwork.ElrondTestnet,
    searchQueries: ["tegld", "tmultiversx", "multiversx_testnet", "multiversx_testnet"],
  },
  BNB: {
    networkName: NetworkName.Bnb,
    label: "BNB",
    hover: "Binance",
    currency: "BNB",
    icon: BNBIcon,
    fetchNetwork: FetchNetwork.Bnb,
    searchQueries: ["bnb", "binance", "l2v2bnb"],
  },
  BNB_TESTNET: {
    networkName: NetworkName.BnbTestnet,
    label: "tBNB",
    hover: "Binance Testnet",
    currency: "BNB",
    icon: BNBIcon,
    fetchNetwork: FetchNetwork.BnbTestnet,
    searchQueries: ["tbnb", "binance_testnet", "binancetestnet"],
  },
  POLYGON: {
    networkName: NetworkName.Polygon,
    label: "MATIC",
    hover: "Polygon",
    currency: "MATIC",
    icon: PolyIcon,
    fetchNetwork: FetchNetwork.Polygon,
    searchQueries: ["matic", "polygon"],
  },
  POLYGON_TESTNET: {
    networkName: NetworkName.PolygonTestnet,
    label: "tMATIC",
    hover: "Polygon Testnet",
    currency: "MATIC",
    icon: PolyIcon,
    fetchNetwork: FetchNetwork.PolygonTestnet,
    searchQueries: ["tmatic", "polygon_testnet", "polygontestnet", "l2v2matic"],
  },
  KUJI: {
    networkName: NetworkName.Kuji,
    label: "KUJI",
    hover: "Kujira",
    currency: "KUJI",
    icon: KujiIcon,
    fetchNetwork: FetchNetwork.Kuji,
    searchQueries: ["kuji", "kujira", "l2v2kuji"],
  },
  KUJI_TESTNET: {
    networkName: NetworkName.KujiTestnet,
    label: "tKUJI",
    hover: "Kujira Testnet",
    currency: "KUJI",
    icon: KujiIcon,
    fetchNetwork: FetchNetwork.KujiTestnet,
    searchQueries: ["tkuji", "kujira_testnet", "kujiratestnet"],
  },
  ARBITRUM: {
    networkName: NetworkName.Arbitrum,
    label: "ARB",
    hover: "Arbitrum",
    currency: "ARB",
    icon: ArbIcon,
    fetchNetwork: FetchNetwork.Arbitrum,
    searchQueries: ["arb", "arbitrum", "l2v2arb"],
  },
  ARBITRUM_TESTNET: {
    networkName: NetworkName.ArbitrumTestnet,
    label: "tARB",
    hover: "Arbitrum Testnet",
    currency: "ARB",
    icon: ArbIcon,
    fetchNetwork: FetchNetwork.ArbitrumTestnet,
    searchQueries: ["tarb", "arbitrum_testnet", "arbitrumtestnet"],
  },
  DEVNET_L1A: {
    networkName: devNetworkName.DevnetL1A,
    label: "devnetA",
    hover: "Devnet A",
    currency: "Cweb",
    icon: CwebIcon,
    fetchNetwork: FetchNetwork.DevnetL1A,
    searchQueries: ["devneta", "devnet_a", "devnet_l1a", "devnetl1a"],
  },
  DEVNET_L1B: {
    networkName: devNetworkName.DevnetL1B,
    label: "devnetB",
    hover: "Devnet B",
    currency: "Cweb",
    icon: CwebIcon,
    fetchNetwork: FetchNetwork.DevnetL1B,
    searchQueries: ["devnetb", "devnet_b", "devnet_l1b", "devnetl1b"],
  },
  TRON: {
    networkName: NetworkName.Tron,
    label: "TRON",
    hover: "Tron",
    currency: "TRX",
    icon: TronIcon,
    searchQueries: ["tron", "trn"],
  },
  TRON_TESTNET: {
    networkName: NetworkName.TronTestnet,
    label: "TRONT",
    hover: "Tron Testnet",
    currency: "TRX",
    icon: TronIcon,
    searchQueries: ["ttron", "ttrn", "tron_testnet", "trontestnet"],
  },
};
